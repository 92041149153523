var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"simpleRules"},[_c('b-overlay',{attrs:{"show":_vm.show,"rounded":"sm"}},[_c('b-form',[_c('b-card',[_c('b-card-title',[_c('h2',[_vm._v(_vm._s(_vm.$t("ProjectForm.Edit_project")))])]),_c('hr'),_c('b-tabs',[_c('b-tab',{attrs:{"active":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('feather-icon',{attrs:{"icon":"Edit3Icon"}}),_c('span',[_vm._v(_vm._s(_vm.$t("dashboard.content")))])]},proxy:true}])},[_c('br'),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{staticClass:"required"},[_vm._v(_vm._s(_vm.$t("ProjectForm.Project_Name"))+" ")]),_c('validation-provider',{attrs:{"rules":"required","name":"Project Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('ProjectForm.Project_Name')},model:{value:(_vm.projectProfile.name),callback:function ($$v) {_vm.$set(_vm.projectProfile, "name", $$v)},expression:"projectProfile.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Project belongs to","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false : null}},[_c('label',{staticClass:"required",attrs:{"for":"owner_type"}},[_vm._v(_vm._s(_vm.$t("ProjectForm.belongs_to"))+" ")]),_c('v-select',{attrs:{"id":"State","options":_vm.$store.state.locale.locale == 'ar'
                          ? _vm.createProjectProfile.OwnerTypeOptionArabic
                          : _vm.createProjectProfile.OwnerTypeOption,"selectable":function (option) { return !option.value.includes('nothing_selected'); },"label":"text"},model:{value:(_vm.projectProfile.typeofOwner),callback:function ($$v) {_vm.$set(_vm.projectProfile, "typeofOwner", $$v)},expression:"projectProfile.typeofOwner"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Project Country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false : null}},[_c('label',{staticClass:"required",attrs:{"for":"Country"}},[_vm._v(_vm._s(_vm.$t("ProjectForm.Project_Country"))+" ")]),_c('v-select',{attrs:{"id":"Country","options":_vm.countries,"selectable":function (option) { return !option.value.includes('nothing_selected'); },"label":"text"},on:{"input":function($event){return _vm.retreiveStates(_vm.projectProfile.profileCountry.value)}},model:{value:(_vm.projectProfile.profileCountry),callback:function ($$v) {_vm.$set(_vm.projectProfile, "profileCountry", $$v)},expression:"projectProfile.profileCountry"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"State","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false : null}},[_c('label',{staticClass:"required",attrs:{"for":"State"}},[_vm._v(_vm._s(_vm.$t("ProjectForm.Project_state"))+" ")]),_c('v-select',{attrs:{"id":"State","options":_vm.statesOptions,"label":"text"},model:{value:(_vm.projectProfile.profileState),callback:function ($$v) {_vm.$set(_vm.projectProfile, "profileState", $$v)},expression:"projectProfile.profileState"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Project city","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('label',{staticClass:"required",attrs:{"for":"City"}},[_vm._v(" "+_vm._s(_vm.$t("ProjectForm.Project_City"))+" ")]),_c('b-form-input',{attrs:{"id":"City","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('ProjectForm.Project_City')},model:{value:(_vm.projectProfile.profileCity),callback:function ($$v) {_vm.$set(_vm.projectProfile, "profileCity", $$v)},expression:"projectProfile.profileCity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"highlights","rules":("required|max:" + _vm.$maxHighlightsProfile)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('label',{staticClass:"required",attrs:{"for":"highlights"}},[_vm._v(_vm._s(_vm.$t("ProjectForm.Brief_overview"))+" ")]),_c('b-form-textarea',{attrs:{"id":"highlights","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('ProjectForm.Brief_overview'),"rows":"2","max-rows":"2","no-auto-shrink":""},model:{value:(_vm.projectProfile.highlights),callback:function ($$v) {_vm.$set(_vm.projectProfile, "highlights", $$v)},expression:"projectProfile.highlights"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Project owner description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('label',{staticClass:"required",attrs:{"for":"Owner-Details"}},[_vm._v(_vm._s(_vm.$t("ProjectForm.owner_description"))+" ")]),_c('b-form-textarea',{attrs:{"id":"Owner-Details","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('ProjectForm.owner_description'),"rows":"3","max-rows":"3","no-auto-shrink":""},model:{value:(_vm.projectProfile.detailsofOwner),callback:function ($$v) {_vm.$set(_vm.projectProfile, "detailsofOwner", $$v)},expression:"projectProfile.detailsofOwner"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('label',{staticClass:"required",attrs:{"for":"description"}},[_vm._v(_vm._s(_vm.$t("ProjectForm.description_project"))+" ")]),_c('b-form-textarea',{attrs:{"id":"description","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('ProjectForm.description_project_p'),"rows":"3","max-rows":"3","no-auto-shrink":""},model:{value:(_vm.projectProfile.description),callback:function ($$v) {_vm.$set(_vm.projectProfile, "description", $$v)},expression:"projectProfile.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Industries","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false : null}},[_c('label',{staticClass:"required",attrs:{"for":"Industry"}},[_vm._v(_vm._s(_vm.$t("ProjectForm.industries_belong"))+" ")]),_c('div',{attrs:{"id":"app"}},[_c('treeselect',{attrs:{"multiple":true,"placeholder":_vm.$t('common.Select'),"options":_vm.industries,"normalizer":_vm.$store.state.locale.locale == 'ar'
                            ? _vm.normalizerArabic
                            : _vm.normalizer},on:{"input":_vm.handeTreeSelect},model:{value:(_vm.projectProfile.profileIndustries),callback:function ($$v) {_vm.$set(_vm.projectProfile, "profileIndustries", $$v)},expression:"projectProfile.profileIndustries"}})],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{staticClass:"required",attrs:{"for":"Assets"}},[_vm._v(_vm._s(_vm.$t("ProjectForm.Describe_physical_assets"))+" ")]),_c('validation-provider',{attrs:{"rules":"required","name":"Assets"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"Assets","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('ProjectForm.Describe_physical_assets')},model:{value:(_vm.projectProfile.assetsType),callback:function ($$v) {_vm.$set(_vm.projectProfile, "assetsType", $$v)},expression:"projectProfile.assetsType"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{staticClass:"required",attrs:{"for":"StafNumber"}},[_vm._v(_vm._s(_vm.$t("ProjectForm.people_need"))+" ")]),_c('validation-provider',{attrs:{"rules":"required|integer","name":"Staf Number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"StafNumber","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('ProjectForm.people_need'),"type":"number"},model:{value:(_vm.projectProfile.staffNumbers),callback:function ($$v) {_vm.$set(_vm.projectProfile, "staffNumbers", $$v)},expression:"projectProfile.staffNumbers"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"2"}},[_c('validation-provider',{attrs:{"name":"BudgetCurrency","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false : null}},[_c('label',{staticClass:"required",attrs:{"for":"BudgetCurrency"}},[_vm._v(_vm._s(_vm.$t("ProjectForm.Budget_Currency"))+" ")]),_c('v-select',{attrs:{"id":"","options":_vm.currencies,"selectable":function (option) { return !option.value.includes('select_value'); },"label":"text"},model:{value:(_vm.projectProfile.budgetCurrencyId),callback:function ($$v) {_vm.$set(_vm.projectProfile, "budgetCurrencyId", $$v)},expression:"projectProfile.budgetCurrencyId"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"4"}},[_c('validation-provider',{attrs:{"name":"BudgeNumber","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false : null}},[_c('label',{staticClass:"required",attrs:{"for":"BudgeNumber"}},[_vm._v(_vm._s(_vm.$t("ProjectForm.expected_budget"))+" ")]),_c('b-form-input',{attrs:{"id":"BudgeNumber","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('ProjectForm.expected_budget')},model:{value:(_vm.projectProfile.budgetAmount),callback:function ($$v) {_vm.$set(_vm.projectProfile, "budgetAmount", $$v)},expression:"projectProfile.budgetAmount"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Project time","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false : null}},[_c('label',{staticClass:"required",attrs:{"for":"ProjectTime"}},[_vm._v(_vm._s(_vm.$t("ProjectForm.set_deadline"))+" ")]),_c('v-select',{attrs:{"id":"ProjectTime","options":_vm.$store.state.locale.locale == 'ar'
                          ? _vm.createProjectProfile.projectTimeOptionsArabic
                          : _vm.createProjectProfile.projectTimeOptions,"selectable":function (option) { return !option.value.includes('select_value'); },"label":"text"},model:{value:(_vm.projectProfile.projectTime),callback:function ($$v) {_vm.$set(_vm.projectProfile, "projectTime", $$v)},expression:"projectProfile.projectTime"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('label',{staticClass:"required",attrs:{"for":"example-datepicker"}},[_vm._v(_vm._s(_vm.$t("ProjectForm.Start_Date"))+" ")]),_c('validation-provider',{attrs:{"rules":"required","name":"StartDate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-form-datepicker',_vm._b({staticClass:"mb-2",attrs:{"id":"StartDate","state":errors.length > 0 ? false : null,"min":_vm.minDate,"locale":_vm.$store.state.locale.locale,"placeholder":_vm.$t('common.No_date_selected')},model:{value:(_vm.projectProfile.startAt),callback:function ($$v) {_vm.$set(_vm.projectProfile, "startAt", $$v)},expression:"projectProfile.startAt"}},'b-form-datepicker',
                          _vm.$store.state.locale.locale == 'ar'
                            ? _vm.labels['ar']
                            : {}
                        ,false)),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('label',{attrs:{"for":"EndDate"}},[_vm._v(_vm._s(_vm.$t("ProjectForm.End_Date"))+" ")]),_c('validation-provider',{attrs:{"name":"EndDate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-datepicker',_vm._b({staticClass:"mb-2",attrs:{"id":"EndDate","state":errors.length > 0 ? false : null,"min":_vm.minDate2,"locale":_vm.$store.state.locale.locale,"placeholder":_vm.$t('common.No_date_selected')},model:{value:(_vm.projectProfile.endAt),callback:function ($$v) {_vm.$set(_vm.projectProfile, "endAt", $$v)},expression:"projectProfile.endAt"}},'b-form-datepicker',
                          _vm.$store.state.locale.locale == 'ar'
                            ? _vm.labels['ar']
                            : {}
                        ,false)),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Category","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false : null}},[_c('label',{staticClass:"required",attrs:{"for":"Category"}},[_vm._v(_vm._s(_vm.$t("ProjectForm.needs_from_sharing"))+" ")]),_c('v-select',{attrs:{"id":"Category","options":_vm.categories,"selectable":function (option) { return !option.value.includes('nothing_selected'); },"label":"text"},on:{"input":function($event){return _vm.resetDataSaleType()}},model:{value:(_vm.projectProfile.category),callback:function ($$v) {_vm.$set(_vm.projectProfile, "category", $$v)},expression:"projectProfile.category"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),(
                  _vm.projectProfile.category.value == '62b40ba9956d948f90a3941e'
                )?_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"sale type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false : null}},[_c('label',{staticClass:"required",attrs:{"for":"saleType"}},[_vm._v(_vm._s(_vm.$t("ProjectForm.sell_project"))+" ")]),_c('v-select',{attrs:{"id":"saleType","options":_vm.$store.state.locale.locale == 'ar'
                          ? _vm.createProjectProfile.SaleTypeOptionArabic
                          : _vm.createProjectProfile.SaleTypeOption,"selectable":function (option) { return !option.value.includes('select_value'); },"label":"text"},model:{value:(_vm.projectProfile.saleType),callback:function ($$v) {_vm.$set(_vm.projectProfile, "saleType", $$v)},expression:"projectProfile.saleType"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,false,3090721982)})],1):_vm._e(),(
                  _vm.projectProfile.saleType != undefined &&
                  _vm.projectProfile.saleType.value == '1' &&
                  _vm.projectProfile.category.value == '62b40ba9956d948f90a3941e'
                )?_c('b-col',{attrs:{"md":"6"}},[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('validation-provider',{attrs:{"name":"Currency","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false : null}},[_c('label',{staticClass:"required",attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t("ProjectForm.Currency"))+" ")]),_c('v-select',{attrs:{"id":"","options":_vm.currencies,"selectable":function (option) { return !option.value.includes('select_value'); },"label":"text"},model:{value:(_vm.projectProfile.currencyId),callback:function ($$v) {_vm.$set(_vm.projectProfile, "currencyId", $$v)},expression:"projectProfile.currencyId"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,false,217795587)})],1),_c('b-col',{attrs:{"md":"8"}},[_c('validation-provider',{attrs:{"name":"Price","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false : null}},[_c('label',{staticClass:"required",attrs:{"for":"Price"}},[_vm._v(_vm._s(_vm.$t("ProjectForm.Price"))+" ")]),_c('b-form-input',{attrs:{"id":"Price","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('ProjectForm.Own_Price')},model:{value:(_vm.projectProfile.priceNumber),callback:function ($$v) {_vm.$set(_vm.projectProfile, "priceNumber", $$v)},expression:"projectProfile.priceNumber"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,false,2600200864)})],1)],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"blockchain exchange","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false : null}},[_c('label',{staticClass:"required",attrs:{"for":"blockchain_exchange"}},[_vm._v(_vm._s(_vm.$t("ProjectForm.blockchain_exchange"))+" ")]),_c('v-select',{attrs:{"id":"blockchain_exchange","options":_vm.$store.state.locale.locale == 'ar'
                          ? _vm.createProjectProfile.ExchangeOptionArabic
                          : _vm.createProjectProfile.ExchangeOption,"selectable":function (option) { return !option.value.includes('nothing_selected'); },"label":"text"},model:{value:(_vm.projectProfile.blockchainExchange),callback:function ($$v) {_vm.$set(_vm.projectProfile, "blockchainExchange", $$v)},expression:"projectProfile.blockchainExchange"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('ProjectForm.Facebook'),"label-for":"facebook"}},[_c('validation-provider',{attrs:{"name":"Facebook","rules":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"facebook","state":errors.length > 0 ? false : null,"placeholder":"https://facebook.com/abc"},model:{value:(_vm.projectProfile.facebook),callback:function ($$v) {_vm.$set(_vm.projectProfile, "facebook", $$v)},expression:"projectProfile.facebook"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('ProjectForm.Instagram'),"label-for":"twitter"}},[_c('validation-provider',{attrs:{"name":"Twitter","rules":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"twitter","state":errors.length > 0 ? false : null,"placeholder":"https://Instagram.com/abc"},model:{value:(_vm.projectProfile.twitter),callback:function ($$v) {_vm.$set(_vm.projectProfile, "twitter", $$v)},expression:"projectProfile.twitter"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('ProjectForm.LinkedIn'),"label-for":"linked-in"}},[_c('validation-provider',{attrs:{"name":"LinkedIn","rules":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"linked-in","state":errors.length > 0 ? false : null,"placeholder":"https://linkedin.com/abc"},model:{value:(_vm.projectProfile.linkedIn),callback:function ($$v) {_vm.$set(_vm.projectProfile, "linkedIn", $$v)},expression:"projectProfile.linkedIn"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('ProjectForm.Website'),"label-for":"Other"}},[_c('validation-provider',{attrs:{"name":"Other","rules":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"Other","state":errors.length > 0 ? false : null,"placeholder":"https://other.com/abc"},model:{value:(_vm.projectProfile.otherURLS),callback:function ($$v) {_vm.$set(_vm.projectProfile, "otherURLS", $$v)},expression:"projectProfile.otherURLS"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"idea-icon","rules":("size:" + _vm.$sizeFilesProfile)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('label',{attrs:{"for":"idea-icon"}},[_vm._v(_vm._s(_vm.$t("ProjectForm.main_project")))]),_c('b-form-file',{attrs:{"state":errors.length > 0 ? false : null,"accept":"image/jpeg, image/png, image/gif","placeholder":_vm.$t('common.Choose_file_drop'),"browse-text":_vm.$t('common.Browse'),"drop-placeholder":"Drop file here..."},model:{value:(_vm.createProjectProfile.icon),callback:function ($$v) {_vm.$set(_vm.createProjectProfile, "icon", $$v)},expression:"createProjectProfile.icon"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"idea-images","rules":("size:" + _vm.$sizeFilesProfile)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('label',{attrs:{"for":"idea-images"}},[_vm._v(_vm._s(_vm.$t("ProjectForm.project_images")))]),_c('b-form-file',{attrs:{"required":"","accept":"image/jpeg, image/png, image/gif","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('common.Choose_file_drop'),"browse-text":_vm.$t('common.Browse'),"drop-placeholder":"Drop file here...","multiple":true},model:{value:(_vm.createProjectProfile.images),callback:function ($$v) {_vm.$set(_vm.createProjectProfile, "images", $$v)},expression:"createProjectProfile.images"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"idea-documents","rules":("size:" + _vm.$sizeFilesProfile)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('label',{attrs:{"for":"idea-documents"}},[_vm._v(_vm._s(_vm.$t("ProjectForm.project_documents"))+" ")]),_c('b-form-file',{attrs:{"accept":"application/pdf, application/vnd.ms-excel,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document","required":"","state":errors.length > 0 ? false : null,"multiple":"","placeholder":_vm.$t('common.Choose_file_drop'),"browse-text":_vm.$t('common.Browse'),"drop-placeholder":"Drop file here..."},model:{value:(_vm.createProjectProfile.docouments),callback:function ($$v) {_vm.$set(_vm.createProjectProfile, "docouments", $$v)},expression:"createProjectProfile.docouments"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"id":"isSold","name":"isSold"},model:{value:(_vm.projectProfile.isSold),callback:function ($$v) {_vm.$set(_vm.projectProfile, "isSold", $$v)},expression:"projectProfile.isSold"}},[_vm._v(" "+_vm._s(_vm.$t("ServiceForm.isSold"))+" ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[(_vm.errorMessage)?_c('b-alert',{staticClass:"warning",staticStyle:{"color":"red"},attrs:{"show":"","variant":"warning"}},[_vm._v(_vm._s(_vm.errorMessage))]):_vm._e(),_c('br'),_c('b-button',{staticClass:"mb-2",attrs:{"variant":"primary","type":"Edit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("common.Edit"))+" ")])],1)],1)],1),_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('feather-icon',{attrs:{"icon":"FileIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t("dashboard.files")))])]},proxy:true}])},[_c('br'),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-table',{attrs:{"striped":"","hover":"","items":_vm.projectProfile.files,"fields":_vm.$store.state.locale.locale == 'ar' ? _vm.fieldsArabic : _vm.fields},scopedSlots:_vm._u([{key:"cell(assets)",fn:function(data){return [_c('div',{directives:[{name:"viewer",rawName:"v-viewer"}],staticClass:"images"},[(data.item.type.includes('image'))?_c('b-avatar',{attrs:{"variant":"primary","src":data.item.fileUrl,"text":"BV"}}):_vm._e()],1),(data.item.type.includes('application'))?_c('a',{attrs:{"href":data.item.fileUrl,"target":"_blank"}},[_vm._v(_vm._s(_vm.$t("dashboard.Preview")))]):_vm._e()]}},{key:"cell(actions)",fn:function(data){return [_c('b-button',{attrs:{"small":"","variant":"danger"},on:{"click":function($event){return _vm.deleteFileById(data.item._id)}}},[_vm._v(" "+_vm._s(_vm.$t("dashboard.Delete"))+" ")])]}}])})],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <validation-observer ref="simpleRules">
    <b-overlay :show="show" rounded="sm">
      <b-form>
        <b-card>
          <b-card-title
            ><h2>{{ $t("ProjectForm.Edit_project") }}</h2>
          </b-card-title>
          <hr />
          <!-- <br /> -->
          <b-tabs>
            <b-tab active>
              <template #title>
                <feather-icon icon="Edit3Icon" />
                <span>{{ $t("dashboard.content") }}</span>
              </template>
              <br />
              <b-row>
                <!--  name-->
                <b-col md="6">
                  <b-form-group>
                    <label class="required"
                      >{{ $t("ProjectForm.Project_Name") }}
                    </label>
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="Project Name"
                    >
                      <b-form-input
                        v-model="projectProfile.name"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('ProjectForm.Project_Name')"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Project belongs to"
                    rules="required"
                  >
                    <b-form-group :state="errors.length > 0 ? false : null">
                      <label class="required" for="owner_type"
                        >{{ $t("ProjectForm.belongs_to") }}
                      </label>

                      <v-select
                        id="State"
                        v-model="projectProfile.typeofOwner"
                        :options="
                          $store.state.locale.locale == 'ar'
                            ? createProjectProfile.OwnerTypeOptionArabic
                            : createProjectProfile.OwnerTypeOption
                        "
                        :selectable="
                          (option) => !option.value.includes('nothing_selected')
                        "
                        label="text"
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Project Country"
                    rules="required"
                  >
                    <b-form-group :state="errors.length > 0 ? false : null">
                      <label class="required" for="Country"
                        >{{ $t("ProjectForm.Project_Country") }}
                      </label>

                      <v-select
                        id="Country"
                        v-model="projectProfile.profileCountry"
                        :options="countries"
                        :selectable="
                          (option) => !option.value.includes('nothing_selected')
                        "
                        label="text"
                        @input="
                          retreiveStates(projectProfile.profileCountry.value)
                        "
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="State"
                    rules="required"
                  >
                    <b-form-group :state="errors.length > 0 ? false : null">
                      <label class="required" for="State"
                        >{{ $t("ProjectForm.Project_state") }}
                      </label>

                      <v-select
                        id="State"
                        v-model="projectProfile.profileState"
                        :options="statesOptions"
                        label="text"
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="6">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Project city"
                      rules="required"
                    >
                      <label class="required" for="City">
                        {{ $t("ProjectForm.Project_City") }}
                      </label>
                      <b-form-input
                        id="City"
                        v-model="projectProfile.profileCity"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('ProjectForm.Project_City')"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="highlights"
                      :rules="`required|max:${$maxHighlightsProfile}`"
                    >
                      <label class="required" for="highlights"
                        >{{ $t("ProjectForm.Brief_overview") }}
                      </label>
                      <b-form-textarea
                        id="highlights"
                        v-model="projectProfile.highlights"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('ProjectForm.Brief_overview')"
                        rows="2"
                        max-rows="2"
                        no-auto-shrink
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Project owner description"
                      rules="required"
                    >
                      <label class="required" for="Owner-Details"
                        >{{ $t("ProjectForm.owner_description") }}
                      </label>

                      <b-form-textarea
                        id="Owner-Details"
                        v-model="projectProfile.detailsofOwner"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('ProjectForm.owner_description')"
                        rows="3"
                        max-rows="3"
                        no-auto-shrink
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="description"
                      rules="required"
                    >
                      <label class="required" for="description"
                        >{{ $t("ProjectForm.description_project") }}
                      </label>

                      <b-form-textarea
                        id="description"
                        v-model="projectProfile.description"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('ProjectForm.description_project_p')"
                        rows="3"
                        max-rows="3"
                        no-auto-shrink
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Industries"
                    rules="required"
                  >
                    <b-form-group :state="errors.length > 0 ? false : null">
                      <label class="required" for="Industry"
                        >{{ $t("ProjectForm.industries_belong") }}
                      </label>
                      <div id="app">
                        <treeselect
                          v-model="projectProfile.profileIndustries"
                          :multiple="true"
                          :placeholder="$t('common.Select')"
                          :options="industries"
                          @input="handeTreeSelect"
                          :normalizer="
                            $store.state.locale.locale == 'ar'
                              ? normalizerArabic
                              : normalizer
                          "
                        />
                      </div>
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col md="6">
                  <b-form-group>
                    <label class="required" for="Assets"
                      >{{ $t("ProjectForm.Describe_physical_assets") }}
                    </label>
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="Assets"
                    >
                      <b-form-input
                        id="Assets"
                        v-model="projectProfile.assetsType"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="
                          $t('ProjectForm.Describe_physical_assets')
                        "
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group>
                    <label class="required" for="StafNumber"
                      >{{ $t("ProjectForm.people_need") }}
                    </label>
                    <validation-provider
                      #default="{ errors }"
                      rules="required|integer"
                      name="Staf Number"
                    >
                      <b-form-input
                        id="StafNumber"
                        v-model="projectProfile.staffNumbers"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('ProjectForm.people_need')"
                        type="number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <validation-provider
                    #default="{ errors }"
                    name="BudgetCurrency"
                    rules="required"
                  >
                    <b-form-group :state="errors.length > 0 ? false : null">
                      <label class="required" for="BudgetCurrency"
                        >{{ $t("ProjectForm.Budget_Currency") }}
                      </label>

                      <v-select
                        id=""
                        v-model="projectProfile.budgetCurrencyId"
                        :options="currencies"
                        :selectable="
                          (option) => !option.value.includes('select_value')
                        "
                        label="text"
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="4">
                  <validation-provider
                    #default="{ errors }"
                    name="BudgeNumber"
                    rules="required|integer"
                  >
                    <b-form-group :state="errors.length > 0 ? false : null">
                      <label class="required" for="BudgeNumber"
                        >{{ $t("ProjectForm.expected_budget") }}
                      </label>
                      <b-form-input
                        id="BudgeNumber"
                        v-model="projectProfile.budgetAmount"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('ProjectForm.expected_budget')"
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Project time"
                    rules="required"
                  >
                    <b-form-group :state="errors.length > 0 ? false : null">
                      <label class="required" for="ProjectTime"
                        >{{ $t("ProjectForm.set_deadline") }}
                      </label>

                      <v-select
                        id="ProjectTime"
                        v-model="projectProfile.projectTime"
                        :options="
                          $store.state.locale.locale == 'ar'
                            ? createProjectProfile.projectTimeOptionsArabic
                            : createProjectProfile.projectTimeOptions
                        "
                        :selectable="
                          (option) => !option.value.includes('select_value')
                        "
                        label="text"
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="6">
                  <b-row>
                    <b-col md="6">
                      <label class="required" for="example-datepicker"
                        >{{ $t("ProjectForm.Start_Date") }}
                      </label>
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="StartDate"
                      >
                        <b-form-datepicker
                          id="StartDate"
                          v-model="projectProfile.startAt"
                          :state="errors.length > 0 ? false : null"
                          class="mb-2"
                          :min="minDate"
                          :locale="$store.state.locale.locale"
                          :placeholder="$t('common.No_date_selected')"
                          v-bind="
                            $store.state.locale.locale == 'ar'
                              ? labels['ar']
                              : {}
                          "
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                    <b-col md="6">
                      <label for="EndDate"
                        >{{ $t("ProjectForm.End_Date") }}
                      </label>
                      <validation-provider #default="{ errors }" name="EndDate">
                        <b-form-datepicker
                          id="EndDate"
                          v-model="projectProfile.endAt"
                          :state="errors.length > 0 ? false : null"
                          class="mb-2"
                          :min="minDate2"
                          :locale="$store.state.locale.locale"
                          :placeholder="$t('common.No_date_selected')"
                          v-bind="
                            $store.state.locale.locale == 'ar'
                              ? labels['ar']
                              : {}
                          "
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                  </b-row>
                </b-col>

                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Category"
                    rules="required"
                  >
                    <b-form-group :state="errors.length > 0 ? false : null">
                      <label class="required" for="Category"
                        >{{ $t("ProjectForm.needs_from_sharing") }}
                      </label>
                      <v-select
                        id="Category"
                        v-model="projectProfile.category"
                        :options="categories"
                        :selectable="
                          (option) => !option.value.includes('nothing_selected')
                        "
                        label="text"
                        @input="resetDataSaleType()"
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col
                  md="6"
                  v-if="
                    projectProfile.category.value == '62b40ba9956d948f90a3941e'
                  "
                >
                  <validation-provider
                    #default="{ errors }"
                    name="sale type"
                    rules="required"
                  >
                    <b-form-group :state="errors.length > 0 ? false : null">
                      <label class="required" for="saleType"
                        >{{ $t("ProjectForm.sell_project") }}
                      </label>

                      <v-select
                        id="saleType"
                        v-model="projectProfile.saleType"
                        :options="
                          $store.state.locale.locale == 'ar'
                            ? createProjectProfile.SaleTypeOptionArabic
                            : createProjectProfile.SaleTypeOption
                        "
                        :selectable="
                          (option) => !option.value.includes('select_value')
                        "
                        label="text"
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  v-if="
                    projectProfile.saleType != undefined &&
                    projectProfile.saleType.value == '1' &&
                    projectProfile.category.value == '62b40ba9956d948f90a3941e'
                  "
                  md="6"
                >
                  <b-row>
                    <b-col md="4">
                      <validation-provider
                        #default="{ errors }"
                        name="Currency"
                        rules="required"
                      >
                        <b-form-group :state="errors.length > 0 ? false : null">
                          <label class="required" for=""
                            >{{ $t("ProjectForm.Currency") }}
                          </label>
                          <v-select
                            id=""
                            v-model="projectProfile.currencyId"
                            :options="currencies"
                            :selectable="
                              (option) => !option.value.includes('select_value')
                            "
                            label="text"
                          />
                          <b-form-invalid-feedback
                            :state="errors.length > 0 ? false : null"
                          >
                            {{ errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col md="8">
                      <validation-provider
                        #default="{ errors }"
                        name="Price"
                        rules="required|integer"
                      >
                        <b-form-group :state="errors.length > 0 ? false : null">
                          <label class="required" for="Price"
                            >{{ $t("ProjectForm.Price") }}
                          </label>

                          <b-form-input
                            id="Price"
                            v-model="projectProfile.priceNumber"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="$t('ProjectForm.Own_Price')"
                          />
                          <b-form-invalid-feedback
                            :state="errors.length > 0 ? false : null"
                          >
                            {{ errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="blockchain exchange"
                    rules="required"
                  >
                    <b-form-group :state="errors.length > 0 ? false : null">
                      <label class="required" for="blockchain_exchange"
                        >{{ $t("ProjectForm.blockchain_exchange") }}
                      </label>
                      <v-select
                        id="blockchain_exchange"
                        v-model="projectProfile.blockchainExchange"
                        :options="
                          $store.state.locale.locale == 'ar'
                            ? createProjectProfile.ExchangeOptionArabic
                            : createProjectProfile.ExchangeOption
                        "
                        :selectable="
                          (option) => !option.value.includes('nothing_selected')
                        "
                        label="text"
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    :label="$t('ProjectForm.Facebook')"
                    label-for="facebook"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Facebook"
                      rules="url"
                    >
                      <b-form-input
                        id="facebook"
                        v-model="projectProfile.facebook"
                        :state="errors.length > 0 ? false : null"
                        placeholder="https://facebook.com/abc"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    :label="$t('ProjectForm.Instagram')"
                    label-for="twitter"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Twitter"
                      rules="url"
                    >
                      <b-form-input
                        id="twitter"
                        v-model="projectProfile.twitter"
                        :state="errors.length > 0 ? false : null"
                        placeholder="https://Instagram.com/abc"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    :label="$t('ProjectForm.LinkedIn')"
                    label-for="linked-in"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="LinkedIn"
                      rules="url"
                    >
                      <b-form-input
                        id="linked-in"
                        v-model="projectProfile.linkedIn"
                        :state="errors.length > 0 ? false : null"
                        placeholder="https://linkedin.com/abc"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    :label="$t('ProjectForm.Website')"
                    label-for="Other"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Other"
                      rules="url"
                    >
                      <b-form-input
                        id="Other"
                        v-model="projectProfile.otherURLS"
                        :state="errors.length > 0 ? false : null"
                        placeholder="https://other.com/abc"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="idea-icon"
                      :rules="`size:${$sizeFilesProfile}`"
                    >
                      <label class="" for="idea-icon">{{
                        $t("ProjectForm.main_project")
                      }}</label>
                      <b-form-file
                        v-model="createProjectProfile.icon"
                        :state="errors.length > 0 ? false : null"
                        accept="image/jpeg, image/png, image/gif"
                        :placeholder="$t('common.Choose_file_drop')"
                        :browse-text="$t('common.Browse')"
                        drop-placeholder="Drop file here..."
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="idea-images"
                      :rules="`size:${$sizeFilesProfile}`"
                    >
                      <label class="" for="idea-images">{{
                        $t("ProjectForm.project_images")
                      }}</label>
                      <b-form-file
                        v-model="createProjectProfile.images"
                        required
                        accept="image/jpeg, image/png, image/gif"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('common.Choose_file_drop')"
                        :browse-text="$t('common.Browse')"
                        drop-placeholder="Drop file here..."
                        :multiple="true"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="idea-documents"
                      :rules="`size:${$sizeFilesProfile}`"
                    >
                      <label class="" for="idea-documents"
                        >{{ $t("ProjectForm.project_documents") }}
                      </label>
                      <b-form-file
                        v-model="createProjectProfile.docouments"
                        accept="application/pdf, application/vnd.ms-excel,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        required
                        :state="errors.length > 0 ? false : null"
                        multiple
                        :placeholder="$t('common.Choose_file_drop')"
                        :browse-text="$t('common.Browse')"
                        drop-placeholder="Drop file here..."
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group>
                    <validation-provider #default="{ errors }" name="">
                      <b-form-checkbox
                        id="isSold"
                        v-model="projectProfile.isSold"
                        name="isSold"
                      >
                        {{ $t("ServiceForm.isSold") }}
                      </b-form-checkbox>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- submit button -->
                <b-col cols="12">
                  <b-alert
                    v-if="errorMessage"
                    show
                    variant="warning"
                    class="warning"
                    style="color: red"
                    >{{ errorMessage }}</b-alert
                  >
                  <br />
                  <b-button
                    variant="primary"
                    type="Edit"
                    class="mb-2"
                    @click.prevent="validationForm"
                  >
                    {{ $t("common.Edit") }}
                  </b-button>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab>
              <template #title>
                <feather-icon icon="FileIcon" />
                <span>{{ $t("dashboard.files") }}</span>
              </template>
              <br />
              <b-row>
                <b-col cols="12">
                  <b-table
                    striped
                    hover
                    :items="projectProfile.files"
                    :fields="
                      $store.state.locale.locale == 'ar' ? fieldsArabic : fields
                    "
                  >
                    <template #cell(assets)="data">
                      <div v-viewer class="images">
                        <b-avatar
                          v-if="data.item.type.includes('image')"
                          variant="primary"
                          :src="data.item.fileUrl"
                          text="BV"
                        />
                      </div>
                      <a
                        v-if="data.item.type.includes('application')"
                        :href="data.item.fileUrl"
                        target="_blank"
                        >{{ $t("dashboard.Preview") }}</a
                      >
                    </template>
                    <template #cell(actions)="data">
                      <b-button
                        small
                        variant="danger"
                        @click="deleteFileById(data.item._id)"
                      >
                        {{ $t("dashboard.Delete") }}
                      </b-button>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
        </b-card>
      </b-form>
    </b-overlay>
    <!-- {{ filterCountry("62a09cd026bb3115ad55e5a9") }} -->
  </validation-observer>
</template>

<script>
import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";

import axios from "axios";

import Treeselect from "@riophae/vue-treeselect";
// import the styles
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { mapActions, mapGetters } from "vuex";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import vue2Dropzone from "vue2-dropzone";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
// import VuePhoneNumberInput from 'vue-phone-number-input'
// import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import "../../../../layouts/landpage/ValidationFrom.js";

import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCard,
  BFormDatepicker,
  BFormTextarea,
  BPopover,
  BFormInvalidFeedback,
  BAlert,
  BCardTitle,
  BFormFile,
  BTable,
  BAvatar,
  BOverlay,
  BFormCheckbox,
  BTabs,
  BTab,
} from "bootstrap-vue";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  digits,
  alphaDash,
  length,
} from "@validations";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCard,
    vSelect,
    BFormFile,
    BTable,
    BAvatar,
    VueViewer,
    // eslint-disable-next-line vue/no-unused-components
    BFormDatepicker,
    BFormTextarea,
    vueDropzone: vue2Dropzone,
    BFormInvalidFeedback,
    BCardTitle,
    Treeselect,
    // VuePhoneNumberInput,
    ToastificationContent,
    BAlert,
    BOverlay,
    BFormCheckbox,
    BTabs,
    BTab,
  },
  data() {
    const url = window.location.href;
    const profileId = url.split("/").slice(-1)[0];
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const minDate = new Date(today);
    const minDate2 = new Date(today);
    minDate2.setDate(minDate2.getDate() + 1);
    return {
      show: false,
      boxTwo: "",
      labels: {
        ar: {
          weekdayHeaderFormat: "narrow",
          labelPrevDecade: "العقد السابق",
          labelPrevYear: "العام السابق",
          labelPrevMonth: "الشهر السابق",
          labelCurrentMonth: "الشهر الحالي",
          labelNextMonth: "الشهر المقبل",
          labelNextYear: "العام المقبل",
          labelNextDecade: "العقد القادم",
          labelToday: "اليوم",
          labelSelected: "التاريخ المحدد",
          labelNoDateSelected: "لم يتم اختيار تاريخ",
          labelCalendar: "التقويم",
          labelNav: "الملاحة التقويم",
          labelHelp: "استخدم مفاتيح المؤشر للتنقل في التواريخ",
        },
      },
      fields: [
        {
          key: "name",
          sortable: true,
        },
        {
          key: "assets",
          sortable: false,
        },
        {
          key: "actions",
          sortable: false,
        },
      ],
      fieldsArabic: [
        {
          label: "اسم الملف",
          key: "name",
          sortable: true,
        },
        {
          label: "الملف",
          key: "assets",
          sortable: false,
        },
        {
          label: "الإجراء",
          key: "actions",
          sortable: false,
        },
      ],
      profileId: profileId,
      errorMessage: "",
      projectTimeValue: "",

      minDate,
      minDate2,
      isValidNumber: null,
      projectProfile: {
        name: "",
        typeofOwner: "",
        description: "",
        highlights: "",
        detailsofOwner: "",
        userId: {
          username: "",
        },
        address: {
          profileCity: "",
          profileState: "",
          profileCountry: {
            name: "",
          },
        },
        socialMedia: {
          facebook: "",
          linkedIn: "",
          twitter: "",
          otherURLS: "",
        },
        blockchainExchange: "",
        price: {
          priceNumber: "",
          currencyId: {
            name: "",
          },
        },
        category: {
          name: "",
        },
        saleType: "",
        profileIndustries: null,
        rating: "4",
        totalComments: "",
        project: {
          projectTime: "",
          staffNumbers: "",
          startAt: "",
          endAt: "",
          budgetAmount: "",
          budgetCurrencyId: {
            name: "",
          },
        },
        isSold: false,
      },
      dropzoneOptionsIcon: {
        addRemoveLinks: true,
        url: "https://httpbin.org/post",
        thumbnailWidth: 120,
        thumbnailHeight: 120,
        acceptedFiles: "image/*",
        maxFilesize: 3,
        maxFiles: 1,
        headers: { "My-Awesome-Header": "header value" },
      },
      dropzoneOptionsImage: {
        addRemoveLinks: true,
        url: "https://httpbin.org/post",
        thumbnailWidth: 120,
        thumbnailHeight: 120,
        acceptedFiles: "image/*",
        maxFilesize: 3,
        maxFiles: 5,
        headers: { "My-Awesome-Header": "header value" },
      },
      dropzoneOptionsProof: {
        addRemoveLinks: true,
        url: "https://httpbin.org/post",
        thumbnailWidth: 120,
        thumbnailHeight: 120,
        acceptedFiles: ".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf",
        maxFilesize: 5,
        headers: { "My-Awesome-Header": "header value" },
      },
      createProjectProfile: {
        budgetCurrency: "",
        budgetPrice: "",
        assets: "",
        staffNumber: "",
        selectedState: null,
        name: "",
        selectedOwnerType: "",
        OwnerDetails: "",
        highlights: "",
        description: "",
        selectedCountry: null,
        selectedIndustry: "",
        twitterUrl: "",
        facebookUrl: "",
        OtherUrl: "",
        linkedinUrl: "",
        selectedExchange: "",
        selectedCategory: "",
        selectedSaleType: "",
        SelectedCurrency: "",
        Price: "",
        selectedStatus: "",
        Reference: "",
        start: "",
        end: "",
        icon: null,
        images: [],
        docouments: [],
        industries: [],
        // phoneNumber: '',
        // phoneCode: '',
        // email: '',
        OwnerTypeOption: [
          { value: "individual", text: "Individual" },
          { value: "group", text: "Group" },
        ],
        OwnerTypeOptionArabic: [
          { value: "individual", text: "فرد" },
          { value: "group", text: "مجموعة" },
        ],
        CountryOption: [
          { value: "nothing_selected", text: "Nothing Selected" },
          { value: "English", text: "English" },
          { value: "Chinese", text: "Mandarin Chinese" },
          { value: "Hindi", text: "Hindi" },
          { value: "Spanish", text: "Spanish" },
          { value: "Arabic", text: "Arabic" },
          { value: "Malay", text: "Malay" },
          { value: "Russian", text: "Russian" },
        ],
        ExchangeOption: [
          { value: "true", text: "Yes" },
          { value: "false", text: "No" },
        ],
        ExchangeOptionArabic: [
          { value: "true", text: "نعم" },
          { value: "false", text: "لا" },
        ],
        SaleTypeOption: [
          { value: "1", text: "My Own Price" },
          { value: "2", text: "Auction" },
        ],
        SaleTypeOptionArabic: [
          { value: "1", text: "سعري الخاص" },
          { value: "2", text: "مزاد" },
        ],
        projectTimeOptions: [
          { value: "1", text: "Open" },
          { value: "2", text: "Scheduled" },
        ],
        projectTimeOptionsArabic: [
          { value: "1", text: "مفتوح" },
          { value: "2", text: "مجدول" },
        ],

        StatusOption: [
          { value: "1", text: "Complete " },
          { value: "2", text: "Incomplete" },
        ],
      },
    };
  },
  computed: {
    ...mapGetters({
      categories: "profile/getProjectCategories",
      industries: "profile/getAllIndustries",
      currencies: "currency/getCurrencies",
      countries: "country/getCountries",
      statesOptions: "country/getAllStates",
      user: "auth/user",
      // projectProfile: "profile/getProjectProfile",
    }),
  },
  methods: {
    ...mapActions({
      fetchCategories: "profile/retreiveCategories",
      fetchIndustries: "profile/retreiveIndustries",
      editProject: "profile/editProject",
      // retreiveProjectProfile: "profile/retreiveProjectProfile",
      fetchCurrencies: "currency/retreiveCurrencies",
      fetchCountries: "country/retreiveCountries",
      fetchStates: "country/retreiveStates",
      deleteFile: "profile/deleteFile",
    }),
    resetDataSaleType() {
      // this.projectProfile.saleType = "";
      this.projectProfile.currencyId = "";
      this.projectProfile.priceNumber = "";
    },
    normalizerArabic(node) {
      return {
        id: node.id,
        label: node.arabicName != null ? node.arabicName : node.label,
        children: node.children,
      };
    },
    normalizer(node) {
      return {
        id: node.id,
        label: node.label,
        children: node.children,
      };
    },
    handeTreeSelect(data) {
      if (data.length > 6) {
        this.projectProfile.profileIndustries.length = 6;
      }
    },

    filterCountry(countryId) {
      // var data = {
      //   specs: this.countries,
      // };

      var valObj = this.countries.filter(function (elem) {
        if (elem.value == countryId) return elem.text;
      });

      if (valObj.length > 0) console.log(valObj[0].text);
      // var valObj = this.countries.filter(function (elem) {
      //   if (elem.value == "62a09cd026bb3115ad55e598") return elem.Value;
      //   console.log("adasd");
      // });
      // const countriee = this.countries.find(
      //   (q, value) => value === "62a09cd026bb3115ad55e598"
      // );
      // return countriee;
      // console.log(countriee);
      // return this.countries.filter((q) =>
      //   countries.value.includes("62a09cd026bb3115ad55e598")
      // );
      // var marvelHeroes = this.countries.filter(function (hero) {
      //   return hero.value == "5";
      // });
    },

    retreiveStates(data) {
      this.fetchStates(data);
    },
    removeThisFile(thisFile) {
      this.$refs.MyDropzone.removeFile(thisFile);
    },
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.show = true;
          const profileData = new FormData();
          // const profileProjectData = {};
          // profileProjectData.profileId = this.projectProfile._id;
          profileData.append("profileId", this.projectProfile._id);
          profileData.append("name", this.projectProfile.name);
          profileData.append(
            "typeofOwner",
            this.projectProfile.typeofOwner.value
          );
          profileData.append(
            "profileCountry",
            this.projectProfile.profileCountry.value
          );
          profileData.append(
            "profileIndustries",
            JSON.stringify(this.projectProfile.profileIndustries)
          );
          if (this.projectProfile.facebook) {
            profileData.append("facebook", this.projectProfile.facebook);
          }
          if (this.projectProfile.twitter) {
            profileData.append("twitter", this.projectProfile.twitter);
          }
          if (this.projectProfile.linkedIn) {
            profileData.append("linkedIn", this.projectProfile.linkedIn);
          }
          if (this.projectProfile.otherURLS) {
            profileData.append("otherURLS", this.projectProfile.otherURLS);
          }

          profileData.append(
            "blockchainExchange",
            this.projectProfile.blockchainExchange.value
          );
          profileData.append("category", this.projectProfile.category.value);

          if (
            this.projectProfile.saleType &&
            this.projectProfile.category.value == "62b40ba9956d948f90a3941e"
          ) {
            profileData.append("saleType", this.projectProfile.saleType.value);
            if (this.projectProfile.saleType.value == "1") {
              profileData.append(
                "currencyId",
                this.projectProfile.currencyId.value
              );
              profileData.append(
                "priceNumber",
                this.projectProfile.priceNumber
              );
            }
          }

          profileData.append("description", this.projectProfile.description);
          profileData.append(
            "detailsofOwner",
            this.projectProfile.detailsofOwner
          );
          profileData.append("highlights", this.projectProfile.highlights);

          profileData.append("profileCity", this.projectProfile.profileCity);
          profileData.append(
            "profileState",
            this.projectProfile.profileState.value
          );
          profileData.append("profileType", "project");
          profileData.append(
            "projectTime",
            this.projectProfile.projectTime.value
          );
          profileData.append("startAt", this.projectProfile.startAt);
          if (this.projectProfile.projectTime.value === "2") {
            profileData.append("endAt", this.projectProfile.endAt);
          }
          profileData.append("staffNumbers", this.projectProfile.staffNumbers);
          profileData.append("assetsType", this.projectProfile.assetsType);
          profileData.append("budgetAmount", this.projectProfile.budgetAmount);
          profileData.append(
            "budgetCurrencyId",
            this.projectProfile.budgetCurrencyId.value
          );

          profileData.append("isSold", this.projectProfile.isSold);

          if (this.createProjectProfile.icon) {
            profileData.append("profileIcon", this.createProjectProfile.icon);
          }
          if (this.createProjectProfile.images) {
            for (const i of Object.keys(this.createProjectProfile.images)) {
              profileData.append(
                "profileImages",
                this.createProjectProfile.images[i]
              );
            }
          }
          if (this.createProjectProfile.docouments) {
            for (const i of Object.keys(this.createProjectProfile.docouments)) {
              profileData.append(
                "profileDocuments",
                this.createProjectProfile.docouments[i]
              );
            }
          }

          // eslint-disable-next-line no-underscore-dangle
          profileData.append("userId", this.user._id);
          // profileProjectData.profileDataForm = profileData;
          this.editProject(profileData)

            .then((response) => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: this.$t("messages.Modified_successfully"),
                  icon: "CoffeeIcon",
                  variant: "success",
                },
              });
              this.show = false;
              this.$router.push({ name: "project-listAll" });
            })
            .catch((error) => {
              this.show = false;
              this.errorMessage = error.response.data.error.message;
            });
        }
      });
    },
    getProjectProfile() {
      this.projectProfile.typeofOwner = {
        value:
          this.projectProfile.typeofOwner == "individual"
            ? "individual"
            : "group",
        text:
          this.projectProfile.typeofOwner == "individual"
            ? this.$store.state.locale.locale == "ar"
              ? "فرد"
              : "Individual"
            : this.$store.state.locale.locale == "ar"
            ? "مجموعة"
            : "Group",
      };

      if (this.projectProfile.address.profileCountry._id) {
        this.projectProfile.profileCountry = {
          value: this.projectProfile.address.profileCountry._id,
          text:
            this.$store.state.locale.locale == "ar"
              ? this.projectProfile.address.profileCountry.arabicName
              : this.projectProfile.address.profileCountry.text,
        };

        this.projectProfile.profileState = JSON.parse(
          this.projectProfile.address.profileState
        );
        this.projectProfile.profileState = {
          value: this.projectProfile.profileState.id,
          text:
            this.$store.state.locale.locale == "ar"
              ? this.projectProfile.profileState.arabicName != undefined
                ? this.projectProfile.profileState.arabicName
                : this.projectProfile.profileState.name
              : this.projectProfile.profileState.name,
        };

        this.projectProfile.profileCity =
          this.projectProfile.address.profileCity;
      } else {
        this.projectProfile.profileCountry = {
          value: "",
          text: "",
        };
      }

      this.projectProfile.category = {
        value: this.projectProfile.category.value,
        text:
          this.$store.state.locale.locale == "ar"
            ? this.projectProfile.category.arabicName
            : this.projectProfile.category.text,
      };

      this.projectProfile.assetsType = this.projectProfile.project.assetsType;

      this.projectProfile.staffNumbers =
        this.projectProfile.project.staffNumbers;

      this.projectProfile.projectTime = {
        value: this.projectProfile.project.projectTime == "1" ? "1" : "2",
        text:
          this.projectProfile.project.projectTime == "1"
            ? this.$store.state.locale.locale == "ar"
              ? "مفتوح"
              : "Open"
            : this.$store.state.locale.locale == "ar"
            ? "مجدول"
            : "Scheduled",
      };

      this.projectProfile.startAt = this.projectProfile.project.startAt;

      this.projectProfile.endAt = this.projectProfile.project.endAt;

      this.projectProfile.budgetAmount =
        this.projectProfile.project.budgetAmount;

      this.projectProfile.budgetCurrencyId = {
        value: this.projectProfile.project.budgetCurrencyId._id,
        text: this.projectProfile.project.budgetCurrencyId.text,
      };

      if (this.projectProfile.saleType) {
        if (this.projectProfile.saleType == "1") {
          this.projectProfile.saleType = {
            value: this.projectProfile.saleType,
            text:
              this.$store.state.locale.locale == "ar"
                ? "سعري الخاص"
                : "My Own Price",
          };
          this.projectProfile.priceNumber = this.projectProfile.price
            .priceNumber
            ? this.projectProfile.price.priceNumber
            : "";

          this.projectProfile.currencyId = {
            value: this.projectProfile.price.currencyId._id,
            text: this.projectProfile.price.currencyId.text,
          };
        } else if (this.projectProfile.saleType == "2") {
          this.projectProfile.saleType = {
            value: this.projectProfile.saleType,
            text: this.$store.state.locale.locale == "ar" ? "مزاد" : "Auction",
          };
        } else {
          this.projectProfile.saleType = {
            value: "",
            text: "",
          };
        }
      }

      this.projectProfile.blockchainExchange = {
        value:
          this.projectProfile.blockchainExchange == true ? "true" : "false",
        text:
          this.projectProfile.blockchainExchange == true
            ? this.$store.state.locale.locale == "ar"
              ? "نعم"
              : "Yes"
            : this.$store.state.locale.locale == "ar"
            ? "لا"
            : "No",
      };
      if (this.projectProfile.socialMedia) {
        this.projectProfile.facebook = this.projectProfile.socialMedia.facebook;

        this.projectProfile.linkedIn = this.projectProfile.socialMedia.linkedIn;

        this.projectProfile.twitter = this.projectProfile.socialMedia.twitter;

        this.projectProfile.otherURLS =
          this.projectProfile.socialMedia.otherURLS;
      }
    },
    pluck(array, key) {
      return array.map((o) => o[key]);
    },
    deleteFileById(id) {
      const deleteData = new FormData();
      deleteData.append("fileId", id);
      deleteData.append("profileId", this.projectProfile._id);
      this.boxTwo = "";
      this.$bvModal
        .msgBoxConfirm(this.$t("messages.Please_confirm_delete"), {
          title: this.$t("messages.Please_Confirm"),
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: this.$t("messages.YES"),
          cancelTitle: this.$t("messages.NO"),
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.deleteFile(deleteData)
              .then((response) => {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: this.$t("messages.Deleted_successfully"),
                    icon: "CoffeeIcon",
                    variant: "success",
                  },
                });
                this.projectProfile.files = this.projectProfile.files.filter(
                  (data) => data._id != id
                );
              })
              .catch((error) => {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: error.response.data.error.message,
                    icon: "CoffeeIcon",
                    variant: "error",
                  },
                });
                this.$router.push({ name: "idea-listAll" });
              });
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
  },
  mounted() {
    this.fetchCategories();
    this.fetchIndustries();
    this.fetchCurrencies();
    this.fetchCountries();
    this.configValidate();

    // this.retreiveProjectProfile(this.profileId);
    axios.get(`profiles/${this.$route.params.id}`).then((response) => {
      if (response.data.data.userId._id == this.$store.state.auth.user._id) {
        this.projectProfile = response.data.data;

        this.projectProfile.profileIndustries = this.pluck(
          this.projectProfile.profileIndustries,
          "id"
        );
        this.getProjectProfile();
      } else {
        window.location.href = "/error-404";
      }
    });
  },
};
</script>

<style type="text/css">
@charset "UTF-8";

[dir] label {
  font-size: 14px;
}
[dir] .form-control {
  padding: 0.438rem 1rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  font-size: 15px;
}

[dir] .vue-form-wizard .wizard-card-footer .wizard-footer-right .wizard-btn {
  background-color: #366ce3;
  border-color: #2d5dc7;
}
[dir] .vs__selected {
  background-color: #366ce3;
}
.col-md-6 {
  margin-bottom: 5px;
}

.required:after {
  content: " *";
  color: red;
}
[dir] .dropzone.dz-clickable {
  cursor: pointer;
  min-height: 150px;
  max-height: 260px;
  overflow-y: scroll;
}
</style>
